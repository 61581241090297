import axios, { AxiosInstance } from 'axios';
import { checkAuthCookies, login } from './utils';

export const client: AxiosInstance = axios.create({
  baseURL: window.location.origin + '/api/internal',
  withCredentials: import.meta.env.VITE_ENABLE_AUTH !== 'false'
});

if (import.meta.env.VITE_ENABLE_AUTH !== 'false') {
  document.addEventListener('visibilitychange', async () => {
    if (document.visibilityState === 'visible') {
      await checkAuthCookies();
    }
  });

  window.addEventListener('focus', async () => {
    await checkAuthCookies();
  });

  client.interceptors.request.use(
    async (config) => {
      await checkAuthCookies();

      return config;
    },
    (error) => Promise.reject(new Error(error))
  );

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        await checkAuthCookies();
      }

      if (error.response && error.response.status === 403) {
        await login();
      }

      if (error.response && error.response.status === 418) {
        window.location.href = window.location.origin;
      }

      return Promise.reject(new Error(error));
    }
  );
}
